<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-title>Nastavení</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <ProfileCard :editable="true" />
      <h4>Vaše socialní sítě</h4>
      <p style="text-align: center">
        <strong>Lze mít aktivní poze jednu socialní síť.</strong> <br />
        Klepnutím na jméno sítě ji aktivujete.
      </p>
      <ion-list lines="full">
        <template v-if="getSocialList.length != 0">
          <ion-item v-for="item in getSocialList" :key="item.id">
            <ion-label
              @click="changeActiveId(item.id)"
              :class="item.id == getActiveId ? 'active' : ''"
            >
              <h2>{{ item.name }}</h2>
            </ion-label>
            <ion-buttons slot="end">
              <ion-button @click="leaveSocial(item.id)">
                <ion-icon :icon="logOutOutline"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-item>
        </template>
        <template v-else>
          <ion-item>
            <ion-label>
              <h2>Nejste členem žádné sítě</h2>
            </ion-label>
          </ion-item>
        </template>
      </ion-list>
      <ion-button
        class="addBtn"
        @click="() => $router.push(`/tabs/socialEnter`)"
      >
        <ion-icon :icon="add"></ion-icon> <span>Přidat síť</span></ion-button
      >

      <div class="divider">
        <div class="shadow"></div>
      </div>
      <div class="">
        <h4>Odhlášení</h4>
        <ion-button @click="logOutPrompt()" class="logoutButton" color="danger">
          <span>Odhlásit se</span></ion-button
        >
      </div>
      <div class="info">
        <h4>Informace o aplikaci</h4>
        <p>
          Aplikace včetně jejího obsahu byla realizována v rámci projektu
          "Neurotechnologie ke zvýšení kvality života a Prevence kyberšikany ve
          Společnosti 4.0" pod r.č.: "CZ.07.1.02/0.0/0.0/17_049/0000835", který
          byl finančně podpořen z "Operačního programu PRAHA – PÓL RŮSTU ČR" (OP
          – PPR), konkrétně výzvou č. 32 – Podpora transferu technologií a
          znalostí z výzkumných organizací do praxe III.
        </p>
        <img
          class="eu-logo"
          :src="require('@//assets/img/logo_projektu.jpg')"
        />
        <p>
          © 2021 České vysoké učení technické v Praze Aplikace SoSi využívá
          platformu © iMuseum a je chráněna dle § 2 odst. 2 autorského zákona
          (121/2000 Sb.)
        </p>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonTitle,
  IonToolbar,
  IonHeader,
  IonPage,
  IonFabButton,
  IonFab,
  IonIcon,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonButton,
  IonButtons,
  alertController,
  toastController,
} from "@ionic/vue";
import { add, exitOutline, logOutOutline } from "ionicons/icons";
import { mapActions, mapGetters } from "vuex";
import ProfileCard from "../components/ProfileCard.vue";
import { emitter } from "../eventBus";

export default {
  name: "Menu",
  components: {
    IonTitle,
    IonToolbar,
    IonHeader,
    IonPage,
    IonContent,
    IonLabel,
    IonButton,
    IonButtons,
    IonList,
    IonItem,
    IonIcon,
    ProfileCard,
  },
  setup() {
    return {
      add,
      exitOutline,
      logOutOutline,
    };
  },
  data() {
    return {
      // socialList: [],
    };
  },
  beforeMount() {
    let self = this;
    this.downloadListOfUsersSocial();
  },
  computed: {
    ...mapGetters({
      getActiveId: "social/getActiveId",
      getSocialList: "social/getSocialList",
    }),
  },
  methods: {
    ...mapActions({
      downloadListOfUsersSocial: "social/downloadListOfUsersSocial",
      setActiveSocial: "social/setActiveSocial",
      downloadSocialDetail: "social/downloadSocialDetail",
      leaveSocialNetwork: "social/leaveSocialNetwork",
      connectToNetworkChannel: "websocket/connectToNetworkChannel",
      connectToChatChannel: "websocket/connectToChatChannel",
      disconnectChannel: "websocket/disconnectChannel",
      downloadAllChats: "chat/downloadAllChats",
      resetCurrentSocialData: "social/resetCurrentSocialData",
    }),
    async openToast(msg, type, position) {
      const toast = await toastController.create({
        message: msg,
        duration: 2000,
        color: type,
        position: position,
      });
      return toast.present();
    },
    changeActiveId(id) {
      if (this.getActiveId != id) {
        this.disconnectChannel({ id: this.getActiveId });

        this.resetCurrentSocialData().then((res) => {
          this.setActiveSocial({ id: id }).then((res) => {
            this.connectToNetworkChannel({ id: id });
            this.connectToChatChannel({ id: id });
            this.openToast("Síť byla přepnuta.", "success", "top");
            this.downloadAllChats({ networkId: this.getActiveId });
            this.$router.push({
              path: `/tabs/social/${this.getActiveId}`,
            });
          });
        });

        // emitter.emit("socialNetworkChanged");
      }
    },
    async leaveSocial(id) {
      const alert = await alertController.create({
        cssClass: "leave-social",
        header: "Opuštění socialní sítě!",
        message: "Opravdu chcete <strong>opustit</strong> tuto socialní síť?!",
        buttons: [
          {
            text: "Zrušit",
            role: "cancel",
            cssClass: "secondary",
            handler: (o) => {
              console.log("Canceled");
            },
          },
          {
            text: "Ano",
            handler: () => {
              this.leaveSocialNetwork({ network: id }).then((res) => {
                this.downloadListOfUsersSocial();
              });
            },
          },
        ],
      });
      return alert.present();
    },
    async logOutPrompt() {
      const alert = await alertController.create({
        cssClass: "leave-social",
        header: "Opravu se chcete odhlásit?",
        message:
          "Odhlášením příjdete o přístup do Vašich socialních sítí a také o výsledky kvízů.",
        buttons: [
          {
            text: "Zrušit",
            role: "cancel",
            cssClass: "primary",
            handler: (o) => {
              console.log("Canceled");
            },
          },
          {
            text: "Odhlásit",
            role: "end",
            cssClass: "quit-button",
            handler: (o) => {
              this.logout();
            },
          },
        ],
      });
      return alert.present();
    },
    logout() {
      localStorage.removeItem("sosi_vuex");
      location.reload();
    },
  },
};
</script>

<style scoped>
h4 {
  margin: 25px 0;
  font-weight: 700;
}
.active {
  font-weight: bold;
  color: var(--ion-color-primary);
}
ion-list {
  padding-top: 0px;
  padding-bottom: 0px;
}
.addBtn {
  float: right;
  margin: 15px 0;
}
.divider {
  clear: both;
  margin: auto;
  margin-top: 80px;
  width: 100%;
  position: relative;
  padding: 25px 0;
}

.divider .shadow {
  overflow: hidden;
  height: 20px;
}

.divider .shadow:after {
  content: "";
  display: block;
  margin: -25px auto 0;
  width: 100%;
  height: 25px;
  border-radius: 125px/12px;
  box-shadow: 0 0 8px var(--ion-color-medium);
}
.eu-logo {
  width: 100%;
  max-width: 600px;
}
</style>
